html,
body,
#root,
#root > div {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

html,
body {
  font-size: 12px;
}
